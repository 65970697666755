import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { FeatureFlagName, FeatureFlagsService } from '@common/feature-flags';
import { PLProvidersService } from '@modules/schedule/services';
import { PLAddRecentProvider } from '@modules/search/store/search.store';
import { PLSubNavigationTabs } from '@root/src/app/common/interfaces/pl-sub-navigation-tabs';
import { PLMayService } from '@root/src/lib-components';
import { CurrentUserService } from '../../user/current-user.service';
import { PLProviderService } from '../pl-provider.service';

@Component({
  selector: 'pl-provider',
  templateUrl: './pl-provider.component.html',
  styleUrls: ['./pl-provider.component.less'],
})
export class PLProviderComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  allLoaded = false;
  provider: any = {};
  providerNew: any = {};
  userType: string;
  permissionCode: number;
  mayViewProvider = false;
  newDesign: boolean;
  tabs: PLSubNavigationTabs[] = [];
  isDetailsRoute = false;
  showPageNewOverView = false;
  constructor(
    private plProvider: PLProviderService,
    private $store: Store<any>,
    private featureFlagsService: FeatureFlagsService,
    private router: Router,
    private route: ActivatedRoute,
    private providerService: PLProvidersService,
    private plMayService: PLMayService,
    private plCurrentUserService: CurrentUserService,
  ) {}

  ngOnInit() {
    this.checkRoute(this.router.url);
    this.subscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.checkRoute(event.urlAfterRedirects);
      });
    this.plProvider.getFromRoute().subscribe(
      (res: any) => {
        this.provider = res.provider;
        this.$store.dispatch(PLAddRecentProvider({ provider: this.provider }));
        this.mayViewProvider = res.mayViewProvider;
        this.tabs = this.plProvider.getTabs(this.provider.user);
        this.allLoaded = true;
      },
      (err: any) => {
        this.permissionCode = err.permissionCode;
        this.allLoaded = true;
      },
    );
    this.route.params.subscribe(params => {
      this.providerService.get(undefined, params.id).subscribe((res: any) => {
        this.providerNew = res;
      });
    });

    this.plCurrentUserService.getCurrentUser().subscribe((user: any) => {
      this.userType = this.plMayService.getUserType(user);
    });

    this.featureFlagsService
      .isFeatureEnabled(FeatureFlagName.showNewNavigation)
      .subscribe(enabled => (this.newDesign = enabled));

    this.featureFlagsService
      .isFeatureEnabled(FeatureFlagName.showNewProviderOverviewPage)
      .subscribe(enabled => {
        this.showPageNewOverView = enabled;
      });
  }

  checkRoute(url: string) {
    this.isDetailsRoute =
      url.includes('/provider/') && url.includes('/details');
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
